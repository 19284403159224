<template>
  <ul id="main-menu-navigation" class="nav navbar-nav">
    <component :is="resolveNavComponent(item)" v-if="$CanViewMenu(item)" v-for="item in navMenuItems" :key="item.header || item.title" :item="item" />
  </ul>
</template>

<script>
import HorizontalNavMenuHeaderLink from '../horizontal-nav-menu-header-link/HorizontalNavMenuHeaderLink.vue';
import HorizontalNavMenuHeaderGroup from '../horizontal-nav-menu-header-group/HorizontalNavMenuHeaderGroup.vue';

export default {
  components: {
    HorizontalNavMenuHeaderLink,
    HorizontalNavMenuHeaderGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      navMenuItems : [
        {
          title: this.$t('navbar.home'),
          route: "dashboard",
          icon: "HomeIcon",
          Permission: 'homepage_tab',
        },

        // {
        //   header: 'Users',
        //   icon: 'UsersIcon',
        //   // Permission: 'see_user_menu',
        //   children: [
        //     {
        //       title: 'Add',
        //       route: 'user-add',
        //       icon: 'UserPlusIcon',
        //     },
        //     {
        //       title: 'List',
        //       route: 'user-list',
        //       icon: 'ListIcon',
        //     },
        //     {
        //       title: 'Edit',
        //       route: 'user-edit',
        //       icon: 'EditIcon',
        //       disabled: true,
        //     },
        //   ],
        // },
        
        // {
        //   title: this.$t('navbar.equity'),
        //   route: "equities",
        //   icon: "BarChart2Icon",
        //   Permission: 'equity_tab',
        // },
        // {
        //   title: this.$t('navbar.bond'),
        //   route: "bonds",
        //   icon: "BarChart2Icon",
        //   Permission: 'bond_tab',
        // },
        {
          
          header: this.$t('navbar.equity_bond'),
          title: this.$t('navbar.equity_bond'),
          icon: "BarChart2Icon",
          Permission: 'equity_tab',
          children: [
            {
              title: this.$t('navbar.equity'),
              route: 'equities',
              icon: 'BarChart2Icon',
            },
            {
              title: this.$t('navbar.bond'),
              route: 'bonds',
              icon: 'BarChart2Icon',
            },
          ],
        },
        {
          title: this.$t('navbar.fx_spot_forward_swap'),
          route: "fxsfs",
          icon: "BarChart2Icon",
          Permission: 'fx_tab',
        },

        {
          title: this.$t('navbar.transfers'),
          route: "transfers",
          icon: "BarChart2Icon",
          Permission: 'transfers_tab',
        },

        {
          title: this.$t('navbar.account_creation'),
          route: "account-creation",
          icon: "BarChart2Icon",
          Permission: 'account_tab',
        },

        {
          title: this.$t('navbar.security_creation'),
          route: "security-creation",
          icon: "BarChart2Icon",
          Permission: 'security_tab',
        },

        {
          title: this.$t('navbar.journals'),
          route: "journals",
          icon: "BarChart2Icon",
          Permission: 'journals_tab',
        },

        

        {
          
          header: this.$t('navbar.reports'),
          title: this.$t('navbar.reports'),
          icon: "BarChart2Icon",
          Permission: 'reports_tab',
          children: [
              {
              title: this.$t('navbar.portfolio'),
              route: "portfolios",
              icon: "BarChart2Icon",
              Permission: 'portfolios_tab',
            },
            {
              title: this.$t('navbar.statements'),
              route: 'statements',
              icon: 'BarChart2Icon',
            },
          ],
        },

        {
          title: this.$t('navbar.settings'),
          route: "settings",
          icon: "SettingsIcon",
          Permission: 'settings_tab',
        },
      ]
    }
  },
  setup() {
    const resolveNavComponent = (item) => {
      if (item.children) return 'horizontal-nav-menu-header-group';
      return 'horizontal-nav-menu-header-link';
    };

    return {
      resolveNavComponent,
    };
  },
};
</script>
